import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseButton from '../closeButton';
import imageAndroid from '../../../assets/images/icon_google_play.png';
import imageIos from '../../../assets/images/icon_app_store.png';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    // position: 'sticky',
    left: '0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#fafafa',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 20,
    bottom: 0
  },
  textAndButtonGroupContainer: {
    ...theme.globals.centerVertical,
    textAlign: 'center',
    width: '100%',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 40,
    paddingRight: 40,
  },
  text: {
    ...theme.typography.subtitle,
    color: theme.palette.gray.main,
    paddingBottom: 10,
  },
  buttonGroupContainer: {
    ...theme.globals.center,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  buttonContainer: {
    padding: 5,
  },
  buttonImage: {
    width: 'auto',
    height: '50px',
  },
  closeButtonContainer: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
}));

const PrimaryFooter = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.container}>
      <div className={classes.textAndButtonGroupContainer}>
        <Typography className={classes.text}>
          Recuerda que puedes descargar{' '}
          <span className={classes.greentext}>Nerito para empresas</span>, en
          tu smartphone.
        </Typography>

        <div className={classes.buttonGroupContainer}>
          <div className={classes.buttonContainer}>
            <a
              href="https://play.google.com/store/apps/details?id=com.flexwallet"
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={imageAndroid} className={classes.buttonImage} />
            </a>
          </div>
          {/* <div className={classes.buttonContainer}>
              <a
                // href='https://play.google.com/apps/internaltest/4700453203728180392'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={imageIos} className={classes.buttonImage} />
              </a>
            </div> */}
        </div>
      </div>
      <div className={classes.closeButtonContainer}>
        <CloseButton
          paddingHorizontal='0'
          paddingVertical='0'
          marginHorizontal='0'
          marginVertical='0'
          onClick={props.onClickClose} />
      </div>
    </div>
  );
};

export default PrimaryFooter;
